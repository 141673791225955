import './App.css';
import React, { useState } from 'react';

// Copy-to-Clipboard
import {CopyToClipboard} from 'react-copy-to-clipboard';

// Shoelace
import { SlButton, SlButtonGroup } from '@shoelace-style/shoelace/dist/react';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import '@shoelace-style/shoelace/dist/themes/dark.css';
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.5.1/cdn/');



const App = () => {

    const webAddress = 'https://letsdothis.day';

    const [shareTxt, setShareTxt] = useState('');
    const [shareClipboardTxt, setShareClipboardTxt] = useState(webAddress);

    const buttonStyleFalse = 'default';
    const buttonStyleTrue = 'success';

    const [weekDays, setWeekDays] = useState([
        {number: 0, name: 'Monday', shortName: 'Mon', selected: false, buttonStyle: buttonStyleFalse},
        {number: 1, name: 'Tuesday', shortName: 'Tue', selected: false, buttonStyle: buttonStyleFalse},
        {number: 2, name: 'Wednesday', shortName: 'Wed', selected: false, buttonStyle: buttonStyleFalse},
        {number: 3, name: 'Thursday', shortName: 'Thu', selected: false, buttonStyle: buttonStyleFalse},
        {number: 4, name: 'Friday', shortName: 'Fri', selected: false, buttonStyle: buttonStyleFalse},
        {number: 5, name: 'Saturday', shortName: 'Sat', selected: false, buttonStyle: buttonStyleFalse},
        {number: 6, name: 'Sunday', shortName: 'Sun', selected: false, buttonStyle: buttonStyleFalse},
    ]);

    // const toggleStyles = {
    //     0: 'button-secondary pure-button',
    //     1: 'button-success pure-button',
    // };

    // const [dayButtons, setDayButtons] = useState('');
    const handleToggleDay = (index) => {
        let weekDaysCopy = [...weekDays]
        console.log(index);
        if (weekDaysCopy[index].selected) {
            weekDaysCopy[index].selected = false;
            weekDaysCopy[index].buttonStyle = buttonStyleFalse
        } else {
            weekDaysCopy[index].selected = true;
            weekDaysCopy[index].buttonStyle = buttonStyleTrue
        }
        console.log(weekDaysCopy[index].selected);
        setWeekDays(weekDaysCopy);
        createShareContent();
    };

    const handleCopied = () => {
        let shareApiTxt = createShareContent();
        console.log(shareApiTxt);
        if (navigator.share) {
          navigator.share({
            title: 'Lets Do This Day',
            text: shareApiTxt,
            // url: webAddress,
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            alert('Copied to clipboard!');
        }
        console.log(shareClipboardTxt);
    }

    const createShareContent = () => {
        let newTxt = 'Let\'s Do This Day\n\n';
        let icons = {
            false: '⬛',
            true: '✅',
        };
        for (var i = 0; i < weekDays.length; i++) {
            newTxt += icons[weekDays[i].selected] + ' ' + weekDays[i].name + '\n';
        }
        setShareTxt(newTxt);
        let newTxtClip = newTxt + '\n' + webAddress;
        setShareClipboardTxt(newTxtClip);
        return newTxtClip;
    }

    if (shareTxt === '') {
        createShareContent();
    }

    return (
      <>
        <SlButtonGroup label="Alignment">
        {weekDays.map((item, index) => (
            <SlButton size="medium" variant={item.buttonStyle} key={item.number} onClick={() => handleToggleDay(index)} style={{ width: '4.0em', marginBottom: '1rem' }}>{item.shortName}</SlButton>
        ))}
        </SlButtonGroup>
        <p>
            <CopyToClipboard text={shareClipboardTxt} onCopy={() => handleCopied()}>
                <SlButton variant="primary">Share!</SlButton>
            </CopyToClipboard>
        </p>
      </>
    );
};


export default App;
